<template>
  <InventoryEditAdmin
      v-if="loaded"
      :Inventory="Inventory"
      @save="save"
  />
</template>

<script>
  import InventoryEditAdmin from "./InventoryEditAdmin/InventoryEditAdmin";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
  import {Inventory} from "@/components/modules/InventoryModule/models/Inventory";
  import {InventorySocketMixin} from "@/mixins/socketMixins/socketMixin";

  export default {
    name: "InventoryEdit",
    components: {
      InventoryEditAdmin,
    },

    mixins: [queryFilterMixin, InventorySocketMixin],

    data() {
      return {
        Inventory: new Inventory(),

        loaded: false,
      }
    },

    mounted() {

      let inventoryId = this.$route.params.id
      if(!inventoryId) return

      this.$store.dispatch('getStorageDocumentsEdit', inventoryId).then(response => {
        let inventoryData = this.getRespData(response)

        let myQuery = this.createQueryBuilderObject()

        myQuery.where('byStorageId', inventoryData?.storage_id)

        let url = this.generateGetParamsFromQuery(myQuery, 1000000, 1)
        url += `&user_id=${inventoryData.user_id}`

        this.$store.dispatch('fetchProductsWithCells', url).then((response) => {
          let products = this.getRespData(response)
          // this.setInventoryProductCells(products)

          this.Inventory.setInventoryItem(inventoryData, products)

          this.loaded = true
        }).catch(error => this.createErrorLog(error))

      }).catch(error => this.createErrorLog(error))
    },

    methods: {

      save({draft = false}) {

        this.checkPartialInventory()

        let data = this.Inventory.productsDataGeneration(draft)
        // console.log(data);
        // if(data) return
        let id = this.Inventory.data.Order.getId()

        this.$store.dispatch('updateStorageDocuments', {id: id, data: data}).then(response => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$router.push(this.$store.getters.GET_PATHS.mainSettingsInventory)
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              this.createErrorLog({stack: ''})
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.createErrorLog({stack: ''})
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        }).catch(error => this.createErrorLog(error))
      },

      checkPartialInventory() {
        let elements//, array = []
        elements = document.querySelectorAll('[data-row-name]:checked')

        if(elements.length === 0) return

        this.Inventory.data.productsForCellArray.map(productForCell => {
          productForCell.data.UserMixedProductCellArray.map(userMixedProductCell => {
            userMixedProductCell.data.cellCount = userMixedProductCell.data.storageCount
          })
          productForCell.data.UserProductCellArray.map(userProductCell => {
            userProductCell.data.cellCount = userProductCell.data.storageCount
          })

          productForCell.data.withoutCellEndCounts = productForCell.data.withoutCellDeclaredCounts
        })

        elements.forEach((item) => {
          let dataRow = item.getAttribute('data-row-name')
          let dataArray = dataRow.split(',')
          let count = parseInt(dataArray[0])
          let productId = parseInt(dataArray[1])
          let type = dataArray[2]
          let cellId = null

          if(dataArray.length > 3) {
            cellId = parseInt(dataArray[3])
          }

          this.Inventory.data.productsForCellArray.map(productForCell => {
            if(productForCell.data.Product.product.productObject.id === productId) {
              switch (type) {
                case 'mix' :
                  productForCell.data.UserMixedProductCellArray.map(userMixedProductCell => {
                    if(userMixedProductCell.data.cellSelected.id === cellId){
                      userMixedProductCell.data.cellCount = count
                    }
                  })
                  break
                case 'without' :
                  if(productForCell.data.UserProductCellArray.length === 0) {
                    productForCell.data.withoutCellEndCounts = count
                  }
                  break
                case 'empty' :
                  if(productForCell.data.UserProductCellArray.length === 0) {
                    productForCell.data.withoutCellEndCounts = count
                  }
                  break
                case 'noMix' :
                  productForCell.data.UserProductCellArray.map(userProductCell => {
                    if(userProductCell.data.cellSelected.id === cellId) {
                      userProductCell.data.cellCount = count
                    }
                  })
                  break
              }
            }
          })

        })
      },

    }

  }
</script>

<style scoped>

</style>