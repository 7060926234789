<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="horiz-block">
      <div class="horiz-block__inner">
        <div class="filter-parcel-track flex-column">

          <div class="filter-parcel-track__item"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'inventory_ScanNumber',
        ])"></div>
            <DefaultSelect
                :options="options"
                :optionsLabel="'name'"
                :label="'Type'"
                :selected="filterType"
                @change="changeFilter"
            />
          </div>

          <div class="filter-parcel-track__item"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'inventory_ScanNumber',
        ])"></div>
            <DefaultInput
                :label="$t('inventory_ScanNumber.localization_value.value')"
                :type="'text'"
                v-model="scan"
                @change="changeScanner"
            />
          </div>
          <div class="filter-parcel-track__item"
               v-if="!filterType || (filterType && filterType.type === 'in-bin')"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'inventory_ScanNumber',
        ])"></div>
            <div class="d-flex">
              <DefaultInput
                  class="w-100"
                  :class="{'mr-2': foundBinItem}"
                  :label="'Scan bin'"
                  :type="'text'"
                  v-model="scanBin"
                  @change="changeScannerBin"
              />
              <MainButton
                  v-if="foundBinItem"
                  :value="'Cancel'"
                  @click.native="cancelFoundBin"
              />
            </div>
          </div>
<!--          <div class="filter-parcel-track__item">-->
<!--            <DefaultInput-->
<!--                :label="'Scan Number'"-->
<!--                :type="'text'"-->
<!--                v-model="input02"-->
<!--            />-->
<!--          </div>-->
        </div>
      </div>
    </div>

  </div>
</template>



<script>
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import {ProductCells} from "@/components/globalModels/ProductCells";

  export default {
    name: "InventoryHead",
    components: {
      DefaultSelect,
      MainButton,
      // SearchEngine,
      DefaultInput,
    },

    props: {
      Inventory: Object,
      foundBin: Object,
      filterType: Object,
    },

    data(){
      return{
        scan: '',
        scanBin: '',
        foundBinItem: null,

        options: [
          {
            type: 'in-bin',
            name: 'In bin'
          },
          {
            type: 'without-bin',
            name: 'Without bin'
          },
        ],
      }
    },

    methods: {
      changeScanner(val){
        if(val.length > 0){

          // Якщо бын не вибраний - піднімає весь продукт
          if(!this.foundBin) {
            this.Inventory.data.productsForCellArray.sort((a, b) => {
              const containsId = (item) => {
                return item.data.Product.product.productObject.upc_code === val
              }

              const aContains = containsId(a)
              const bContains = containsId(b)

              // Піднімаємо об'єкти, які містять idValue, на початок
              if (aContains && !bContains) return -1 // a йде перед b
              if (!aContains && bContains) return 1  // b йде перед a
              return 0
            })
          }

          let foundInBin = false
          let itemProduct = null

          let filteredProductsForCellArray = []

          this.Inventory.data.productsForCellArray.map((item) => {
            if(item.data.Product.product.productObject.upc_code === val){

              if(this.filterType) {

                switch (this.filterType.type) {
                  case 'in-bin' :
                    if(item.data.UserProductCellArray.length || item.data.UserMixedProductCellArray.length) {
                      // якщо був вибраний тип в біні і вибраний бін то перевіряємо і записуємо тільки у вибраному біні
                      if( this.foundBin &&
                          (item.data.UserProductCellArray.length && item.data.UserProductCellArray[0].data.cellSelected?.id === this.foundBin?.id) ||
                          (item.data.UserMixedProductCellArray.length && item.data.UserMixedProductCellArray[0].data.cellSelected?.id === this.foundBin?.id)
                      ) {
                        filteredProductsForCellArray.push(item)
                      } else if(!this.foundBin){
                        filteredProductsForCellArray.push(item)
                      }
                    }
                    return
                  case 'without-bin' :
                    if(!item.data.UserProductCellArray.length && !item.data.UserMixedProductCellArray.length) {
                      filteredProductsForCellArray.push(item)
                    }
                    return
                }

              } else if(this.foundBin) {

                if(
                    (item.data.UserProductCellArray.length && item.data.UserProductCellArray[0].data.cellSelected?.id === this.foundBin?.id) ||
                    (item.data.UserMixedProductCellArray.length && item.data.UserMixedProductCellArray[0].data.cellSelected?.id === this.foundBin?.id)
                ) {
                  filteredProductsForCellArray.push(item)
                  foundInBin = true
                }
                itemProduct = item

              } else {
                filteredProductsForCellArray.push(item)
              }
            }
          })

          if(this.foundBin && !this.filterType && !foundInBin) {
            this.addProductToCell(itemProduct)
          }

          if(filteredProductsForCellArray.length) {
            this.addCountProduct(filteredProductsForCellArray)
          }


          // if(foundIndex !== null) {
          //   let copyProductForCell = this._.clone(this.Inventory.data.productsForCellArray[foundIndex])
          //   this.Inventory.data.productsForCellArray.splice(foundIndex, 1)
          //   this.Inventory.data.productsForCellArray.unshift(copyProductForCell)
          //
          //   // this.Inventory.data.productsForCellArray[0].color = true
          //
          // }
        }

        this.scan = ''
      },

      addProductToCell(item) {
        let newItem = this._.cloneDeep(item)
        newItem.data.withoutCellDeclaredCounts = 0
        newItem.data.withoutCellEndCounts = 0
        newItem.data.UserProductCellArray = []
        newItem.data.UserMixedProductCellArray = []

        let cell = new ProductCells([this.foundBin], this.foundBin)
        if(this.foundBin?.mixed) {
          newItem.data.UserMixedProductCellArray.push(cell)
        } else {
          newItem.data.UserProductCellArray.push(cell)
        }
        this.Inventory.data.productsForCellArray.unshift(newItem)
      },

      addCountProduct(filteredProductsForCellArray = []) {
        let foundProduct = null
        let lastCheckProduct = null
        filteredProductsForCellArray.forEach(item => {
          let check = item.checkInventoryFullScanned()

          lastCheckProduct = {check, item}

          if(!check.fullScanned) {
            foundProduct = {check, item}
          }
        })

        if(!foundProduct) {
          lastCheckProduct.item.addScannedProductInventory()
        } else {
          foundProduct.item.addScannedProductInventory()
        }
      },

      changeFilter(item) {
        if(!item) {
          return this.$emit('changeFilterType', null)
        }

        if(item?.type === 'without-bin') {
          this.cancelFoundBin()
        }

        return this.$emit('changeFilterType', item)
      },

      cancelFoundBin() {
        this.scanBin = ''
        this.foundBinItem = null
        this.$emit('changeFoundBin', this.foundBinItem)
      },

      changeScannerBin(val){
        if(val.length > 0){
          if (val.length > 0 && val.indexOf('-') > 0 && val.toLowerCase().indexOf('cell') === 0) {
            let idValue = val.split('-')[1]
            idValue = parseInt(idValue)

            this.foundBinItem = null

            this.Inventory.data.productsForCellArray.sort((a, b) => {

              // Функція для перевірки, чи є idValue у UserProductCellArray або UserMixedProductCellArray
              const containsId = (item) => {
                const inUserProductCellArray = item.data.UserProductCellArray.some(UserProductCell => {
                  if(UserProductCell.data.cellSelected.id === idValue) {
                    this.foundBinItem = UserProductCell.data.cellSelected
                    return true
                  }
                })

                const inUserMixedProductCellArray = item.data.UserMixedProductCellArray.some(UserMixedProductCell => {
                  if(UserMixedProductCell.data.cellSelected.id === idValue) {
                    this.foundBinItem = UserMixedProductCell.data.cellSelected
                    return true
                  }
                })

                // Повертаємо true, якщо знайдено в будь-якому з масивів
                return inUserProductCellArray || inUserMixedProductCellArray
              }

              const aContains = containsId(a)
              const bContains = containsId(b)

              // Піднімаємо об'єкти, які містять idValue, на початок
              if (aContains && !bContains) return -1 // a йде перед b
              if (!aContains && bContains) return 1  // b йде перед a
              return 0
            })

          }

          if(this.foundBinItem) {
            this.$emit('changeFoundBin', this.foundBinItem)
          }
        }

        this.scan = ''
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";
  @import "../../../../../../../scss/colors";

  .horiz-block{
    &__inner{
      padding-top: 40px;
      display: flex;
      width: 100%;
    }

    .filter-parcel-track{
      display: flex;
      width: 100%;

      @include for-1400{
        flex-wrap: wrap;
      }

      &__item{
        width: 100%;
        margin-bottom: 15px;

        @include from-1400 {
          margin-right: 30px;
          max-width: 644px;

          &:last-child{
            margin-right: 0;
          }
        }

        @include for-1120{
          width: 100%;
        }


      }
    }
  }

</style>

