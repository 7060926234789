<template>
  <div class="fragment">
    <div class="site-table-wrap small-table mt-0"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'inventory_SKU',
          'inventory_UPC',
          'inventory_ProductName',
          'inventory_FBM',
          'inventory_scannedTable',
          'inventory_AfterCount',
          'inventory_Cell',
        ])"></div>
<!--      {{foundBin}}-->
      <table class="site-table" v-if="!isMobileFunc()">
        <thead>
        <tr>
          <th v-if="!changesPopup"></th>
          <th>{{ $t(`inventory_SKU.localization_value.value`) }}</th>
          <th>{{ $t(`inventory_UPC.localization_value.value`) }}</th>
          <th>{{ $t(`inventory_ProductName.localization_value.value`) }}</th>
          <th>{{ $t(`inventory_FBM.localization_value.value`) }}</th>
          <th>{{ $t(`inventory_scannedTable.localization_value.value`) }}</th>
          <th>{{ $t(`inventory_AfterCount.localization_value.value`) }}</th>
          <th>{{ $t(`inventory_Cell.localization_value.value`) }}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(item, index) in Inventory.data.productsForCellArray">
<!--          <tr :key="index + '22'">{{item.data}}</tr>-->
          <template>
            <template v-if="!filterType || filterType.type === 'in-bin'">
              <template v-for="(itemNoMixed, indexNoMixed) in item.data.UserProductCellArray"

              >
                <tr v-if="!changesPopup || (changesPopup && item.data.inventoryLine)"
                    :key="index + 'no-mix' + indexNoMixed"
                    :class="{
                    'site-table__gray-row' : _.has(item, 'color') && item.color,
                    'site-table__gray-green' : foundBin && foundBin.id === itemNoMixed.data.cellSelected.id,
                  }">
                  <td v-if="!changesPopup">
<!--                    {{itemNoMixed.data.cellSelected.id}}-->
                    <DefaultCheckbox
                        :dataValue="itemNoMixed.data.cellCount + ',' + item.data.Product.product.productObject.id + ',noMix,' + itemNoMixed.data.cellSelected.id"
                        :value="item.data.checked"
                        @input="val => item.data.checked = val"
                    />
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.sku_code'"
                    />
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.upc_code'"
                    />
                  </td>
                  <td>
                    <div class="table-row">
                      <TooltipTextHelper :fullTextWidth="360">
                        <template slot="text">
                          <ValueHelper
                              :value="item"
                              :deep="'data.Product.product.productObject.name'"
                          />
                        </template>
                        <template slot="paragraph">
                          <ValueHelper
                              :value="item"
                              :deep="'data.Product.product.productObject.name'"
                          />
                        </template>
                      </TooltipTextHelper>
                    </div>
                  </td>
                  <td v-bind:class="{'color-green-important': itemNoMixed.data.storageCount !== 0 && itemNoMixed.data.storageCount == itemNoMixed.data.cellCount}">
                    {{ itemNoMixed.data.storageCount }}
                  </td>
                  <td>
                    <div class="table-row table-row--input">
                      <DefaultInput
                          class="center white w-75px h28-for1600 medium"
                          v-model="itemNoMixed.data.cellCount"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="color-red">
                      {{ getAfterCount(itemNoMixed.data.cellCount, itemNoMixed.data.storageCount) }}
                    </div>
                  </td>
                  <td>
                    {{ itemNoMixed.data.cellSelected.combo_name }}
                  </td>
                </tr>

              </template>
              <template v-for="(itemMixed, indexMixed) in item.data.UserMixedProductCellArray">
                <tr v-if="!changesPopup || (changesPopup && item.data.inventoryLine)"
                    :key="index + 'mix' + indexMixed + item.color"
                    :class="{
                    'site-table__gray-row' : _.has(item, 'color') && item.color,
                    'site-table__gray-green' : foundBin && foundBin.id === itemMixed.data.cellSelected.id,
                  }">
                  <td v-if="!changesPopup">
<!--                    {{itemMixed.data.cellSelected.id}}-->
                    <DefaultCheckbox
                        :dataValue="itemMixed.data.cellCount + ',' + item.data.Product.product.productObject.id + ',mix,' + itemMixed.data.cellSelected.id"
                        :value="item.data.checked"
                        @input="val => item.data.checked = val"
                    />
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.sku_code'"
                    />
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.upc_code'"
                    />
                  </td>
                  <td>
                    <div class="table-row">
                      <TooltipTextHelper :fullTextWidth="360">
                        <template slot="text">
                          <ValueHelper
                              :value="item"
                              :deep="'data.Product.product.productObject.name'"
                          />
                        </template>
                        <template slot="paragraph">
                          <ValueHelper
                              :value="item"
                              :deep="'data.Product.product.productObject.name'"
                          />
                        </template>
                      </TooltipTextHelper>
                    </div>
                  </td>
                  <td v-bind:class="{'color-green-important': itemMixed.data.storageCount !== 0 && itemMixed.data.storageCount == itemMixed.data.cellCount}">
                    {{ itemMixed.data.storageCount }}
                  </td>
                  <td>
                    <div class="table-row table-row--input">
                      <DefaultInput
                          class="center white w-75px h28-for1600 medium"
                          v-model="itemMixed.data.cellCount"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="color-red">
                      {{ getAfterCount(itemMixed.data.cellCount, itemMixed.data.storageCount) }}
                    </div>
                  </td>
                  <td>
                    {{ itemMixed.data.cellSelected.combo_name }}
                  </td>
                </tr>
              </template>
            </template>

            <template v-if="!filterType || filterType.type === 'without-bin'">
              <tr v-if="(parseInt(item.data.withoutCellDeclaredCounts) !== 0 && item.data.withoutCellDeclaredCounts !== '') ||
                  (parseInt(item.data.withoutCellEndCounts) !== 0 && item.data.withoutCellEndCounts !== '')"
                  :key="index+'withoutCell'"
                  :class="{'site-table__gray-row' : _.has(item, 'color') && item.color}">
                <template v-if="!changesPopup || changesPopup && item.data.inventoryLine">
                  <td v-if="!changesPopup">
                    <DefaultCheckbox
                        :dataValue="item.data.withoutCellEndCounts + ',' + item.data.Product.product.productObject.id + ',without'"
                        :value="item.data.checked"
                        @input="val => item.data.checked = val"
                    />
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.sku_code'"
                    />
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.upc_code'"
                    />
                  </td>
                  <td>
                    <div class="table-row">
                      <TooltipTextHelper :fullTextWidth="360">
                        <template slot="text">
                          <ValueHelper
                              :value="item"
                              :deep="'data.Product.product.productObject.name'"
                          />
                        </template>
                        <template slot="paragraph">
                          <ValueHelper
                              :value="item"
                              :deep="'data.Product.product.productObject.name'"
                          />
                        </template>
                      </TooltipTextHelper>
                    </div>
                  </td>
                  <td v-bind:class="{'color-green-important': item.data.withoutCellDeclaredCounts !== 0 && item.data.withoutCellDeclaredCounts == item.data.withoutCellEndCounts}">
                    {{ item.data.withoutCellDeclaredCounts }}
                  </td>
                  <td>
                    <div class="table-row table-row--input">
                      <DefaultInput
                          class="center white w-75px h28-for1600 medium"
                          v-model="item.data.withoutCellEndCounts"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="color-red">
                      {{ getAfterCount(item.data.withoutCellEndCounts, item.data.withoutCellDeclaredCounts) }}
                    </div>
                  </td>
                  <td>

                  </td>
                </template>
              </tr>
              <tr v-if="isEmptyProduct(item)"
                  :class="{'site-table__gray-row' : _.has(item, 'color') && item.color}"
                  :key="index+'dfd'">
                <template v-if="!changesPopup || changesPopup && item.data.inventoryLine">
                  <td v-if="!changesPopup">
                    <DefaultCheckbox
                        :dataValue="item.data.withoutCellDeclaredCounts + ',' + item.data.Product.product.productObject.id + ',empty'"
                        :value="item.data.checked"
                        @input="val => item.data.checked = val"
                    />
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.sku_code'"
                    />
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.upc_code'"
                    />
                  </td>
                  <td>
                    <div class="table-row">
                      <TooltipTextHelper :fullTextWidth="360">
                        <template slot="text">
                          <ValueHelper
                              :value="item"
                              :deep="'data.Product.product.productObject.name'"
                          />
                        </template>
                        <template slot="paragraph">
                          <ValueHelper
                              :value="item"
                              :deep="'data.Product.product.productObject.name'"
                          />
                        </template>
                      </TooltipTextHelper>
                    </div>
                  </td>
                  <td v-bind:class="{'color-green-important': item.data.withoutCellDeclaredCounts !== 0 && item.data.withoutCellDeclaredCounts == item.data.withoutCellEndCounts}">
                    {{ item.data.withoutCellDeclaredCounts }}
                  </td>
                  <td>
                    <div class="table-row table-row--input">
                      <DefaultInput
                          class="center white w-75px h28-for1600 medium"
                          v-model="item.data.withoutCellEndCounts"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="color-red">
                      {{ getAfterCount(item.data.withoutCellEndCounts, item.data.withoutCellDeclaredCounts) }}
                    </div>
                  </td>
                  <td></td>
                </template>
              </tr>
            </template>

          </template>

        </template>

        </tbody>
      </table>

      <div class="table-card" v-if="isMobileFunc()">
        <div class="table-card__list">
          <div class="table-card__item"
               v-for="(item, index) in Inventory.data.productsForCellArray"
               :key="index">
            <InventoryTableMobile
                :item="item"
            />
          </div>
        </div>
      </div>

      <!--      <div class="table-bottom-btn">-->
      <!--        <div class="table-bottom-btn__inner">-->
      <!--          <ShowMore :count="'25'"/>-->
      <!--          <ExportBtn class="table-bottom-btn__right"/>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="detail-cards__section detail-cards__section--btn" v-if="!changesPopup">
        <div class="d-flex align-items-center justify-content-between w-100"
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'common_cancel',
          'common_SaveInDrafts',
          'common_create',
        ])"></div>
          <div class="detail-cards__section-col two-btn">
            <router-link :to="$store.getters.GET_PATHS.mainSettingsInventory" class="site-link site-link--alt brown">
              {{$t('common_cancel.localization_value.value')}}
            </router-link>
            <span class="site-link site-link--alt brown"
                  @click="$emit('save', {draft: true})"
            >
              {{ $t('common_SaveInDrafts.localization_value.value') }}
            </span>
          </div>
          <div class="detail-cards__section-col one-btn">
            <MainButton
                class="sharp"
                :value="$t('common_create.localization_value.value')"
                @click.native="$emit('save', {draft: false})"
            />
          </div>
        </div>

      </div>

    </div>


    <ChangesInventoryPopup
        v-if="isChangesInventoryPopup && !changesPopup"
        :Inventory="Inventory"
        :filterType="filterType"
        @close="closeChangesPopup"
    />
  </div>
</template>

<script>
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import {mixinDetictingMobile} from "@/mixins/mobileFunctions";
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {

inventoryMixin
} from "../../../../../../../mixins/inventoryMixins/inventoryMixin";
import InventoryTableMobile
  from "@/components/modules/InventoryModule/components/InventoryEdit/InventoryEditAdmin/InventoryTable/InventoryTableMobile/InventoryTableMobile";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {InventorySocketMixin} from "@/mixins/socketMixins/socketMixin";
// import ChangesInventoryPopup
//   from "@/components/modules/InventoryModule/popups/ChangesInventoryPopup/ChangesInventoryPopup";


export default {
  name: "InventoryTable",

  components: {
    // ChangesInventoryPopup,
    ChangesInventoryPopup: () => import('@/components/modules/InventoryModule/popups/ChangesInventoryPopup/ChangesInventoryPopup'),
    DefaultCheckbox,
    InventoryTableMobile,
    ValueHelper,
    TooltipTextHelper,
    MainButton,
    DefaultInput,
  },

  mixins: [mixinDetictingMobile, inventoryMixin, InventorySocketMixin],

  props: {
    Inventory: Object,
    foundBin: Object,
    filterType: Object,
    changesPopup: Boolean,
  },

  watch: {
    loadUserAuthorizedData: function () {
      this.initInventorySocket()
    },
  },

  data() {
    return {
      isChangesInventoryPopup: false,
    }
  },

  mounted() {
    // isChangesInventoryPopup
    if(this.hasChangesLine().length && !this.changesPopup) {
      this.isChangesInventoryPopup = true
    }

    if(this.loadUserAuthorizedData) {
      this.initInventorySocket()
    }
  },

  beforeDestroy() {
    if (this.$store.getters.getInventorySocket && !this.changesPopup) {
      this.destroySocketChannel('Inventory', 'setInventorySocket')
    }
  },

  methods: {

    closeChangesPopup() {
      this.Inventory.data.productsForCellArray.forEach(item => {
        return item.data.inventoryLine = null
      })
      this.isChangesInventoryPopup = false
    },

    initInventorySocket() {
      if(!this.loadUserRoleAndAuthorizedData || this.$store.getters.getInventorySocket) return

      /**
       * Create Socket Channel
       * @type {*|{channel, active, event, user}|{active}}
       */
      let prepareSocket = this.prepareCreatePrivateSocketChannel('Inventory')
      console.log(11111122222);
      console.log(prepareSocket);
      if(prepareSocket.active) {
        this.createInventorySocket(
            prepareSocket,
            (response) => {
              console.log(333333);
              console.log(response.data);
              if(this.Inventory.data.Order.getId() === response?.data?.warehouseStorageLine?.document_id) {
                this.Inventory.setInventoryLineChanges(response.data, this)
                this.isChangesInventoryPopup = true
              }
            })
      }
    },

    hasChangesLine() {
      return this.Inventory.data.productsForCellArray.filter(item => {
        return item.data.inventoryLine !== null
      })
    },

  }

}

</script>

<style scoped lang="scss">
.comment-row {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 297px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}


@media (max-width: 1900px) {
  .comment-row {
    width: 250px;
  }
}

@media (max-width: 1400px) {
  .comment-row {
    width: 200px;
  }
}

.small-table th,
.small-table td {
  white-space: nowrap;
}

.w-75px {
  width: 75px;
}

.site-table-wrap {

  @media(min-width: 1120px) {
    padding-bottom: 100px;
  }

  .detail-cards__section--btn {
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
    width: calc(100% - 330px - 30px);

    @media(max-width: 1600px) {
      width: calc(100% - 132px);
    }

    @media(max-width: 1120px) {
      width: calc(100%);
      margin-top: 0;
    }

    @media(max-width: 680px) {
      flex-direction: column;
    }


  }

  .one-btn {
    max-width: 184px;
    width: 100%;


    @media(min-width: 1120px) {
      .sharp {
        font-size: 24px;
      }
    }

    @media(max-width: 680px) {
      justify-content: flex-end;
      max-width: 100%;

      .sharp {
        max-width: 100%;
      }
    }
  }

  .two-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 0;

    > .site-link {
      padding-left: 32px;
      padding-right: 32px;

      @media(max-width: 680px) {
        padding: 15px;
      }
    }
  }
}

.site-table__gray-green {
  background: #c2d9c2 !important;
}

</style>