<template>
  <InventoryTableShowBlock
      :Inventory="Inventory"
      :filteredArray="filteredArray"
      :navTabs="navTabs"
      @changeTab="changeTab"
  />
</template>

<script>
  import InventoryTableShowBlock from "./InventoryTableShowBlock/InventoryTableShowBlock";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
  import {Inventory} from "@/components/modules/InventoryModule/models/Inventory";
  import {radioButtonsFunc} from "@/mixins/creationPageMixins/radioButtonsFunc";

  export default {
    name: "InventoryTableShow",
    components: {
      InventoryTableShowBlock,
    },

    mixins: [queryFilterMixin, radioButtonsFunc],

    watch: {
      getInventoryArray() {
        this.setFilteredTable()
      },
    },

    computed: {
      getInventoryArray() {
        return this.Inventory.data.productsForCellArray.length
      },
    },

    data() {
      return {
        Inventory: new Inventory(),

        navTabs: {
          all: {
            active: true,
            label: this.$t('inventory_all.localization_value.value'),
            name: 'all',
          },
          edited: {
            active: false,
            label: this.$t('inventory_edited.localization_value.value'),
            name: 'edited',
          },
          unscanned: {
            active: false,
            label: this.$t('inventory_unscanned.localization_value.value'),
            name: 'unscanned',
          },
          scanned: {
            active: false,
            label: this.$t('inventory_scanned.localization_value.value'),
            name: 'scanned',
          },
        },

        filteredArray: [],

      }
    },

    mounted() {

      let inventoryId = this.$route.params.id
      if(!inventoryId) return

      this.$store.dispatch('getStorageDocuments', inventoryId).then(response => {
        let inventoryData = this.getRespData(response)

        let myQuery = this.createQueryBuilderObject()

        myQuery.where('byStorageId', inventoryData?.storage_id)

        let url = this.generateGetParamsFromQuery(myQuery, 1000000, 1)
        url += `&user_id=${inventoryData.user_id}`

        this.$store.dispatch('fetchProductsWithCells', url).then((response) => {
          let products = this.getRespData(response)

          this.Inventory.setInventoryItem(inventoryData, products, true)

          this.setFilteredTable()
        }).catch(error => this.createErrorLog(error))

      })
    },

    methods: {
      changeTab(name) {
        this.skipRadioChecked(this.navTabs, 'active')
        this._.find(this.navTabs, {name: name}).active = true
        this.setFilteredTable()
      },

      setFilteredTable() {
        let newArray = []

        if(!this.isAdmin) return this.setFilteredTableUser()

        console.log(123123);
        console.log(this.Inventory.data.productsForCellArray);

        this.Inventory.data.productsForCellArray.map(item => {

          if(this.navTabs.edited.active ) {

            let flag = false
            let flagWithoutCell = false
            let copyItem = this._.cloneDeep(item)
            // item.data.withoutCellDeclaredCounts !== 0 &&
            if(item.data.withoutCellEndCounts !== 0) {
              if(item.data.withoutCellDeclaredCounts !== item.data.withoutCellEndCounts) {
                // newArray.push(item)
                flag = true
                flagWithoutCell = true
              }
            }
            if(item.data.withoutCellDeclaredCounts === 0 && item.data.withoutCellEndCounts !== 0) {
              flag = true
            }

            let productCells = []
            if(copyItem.data.UserProductCellArray.length > 0){
              copyItem.data.UserProductCellArray.map(cell => {
                if(
                    (parseInt(cell.data.storageCount) !== 0 && parseInt(cell.data.cellCount) !== 0) &&
                    parseInt(cell.data.storageCount) !== parseInt(cell.data.cellCount)) {
                  productCells.push(cell)
                  flag = true
                }
              })
            }
            copyItem.data.UserProductCellArray = productCells

            productCells = []
            if(copyItem.data.UserMixedProductCellArray.length > 0){
              copyItem.data.UserMixedProductCellArray.map(cell => {
                if(
                    (parseInt(cell.data.storageCount) !== 0 && parseInt(cell.data.cellCount) !== 0) &&
                    parseInt(cell.data.storageCount) !== parseInt(cell.data.cellCount)) {
                  productCells.push(cell)
                  flag = true
                }
              })
            }
            copyItem.data.UserMixedProductCellArray = productCells

            if(!flagWithoutCell){
              copyItem.data.withoutCellDeclaredCounts = null
              copyItem.data.withoutCellEndCounts = null
            }

            if(flag) {
              newArray.push(copyItem)
            }

          }

          if(this.navTabs.unscanned.active) {
            let flag = false
            let copyItem = this._.cloneDeep(item)
            copyItem.data.withoutCellDeclaredCounts = 0
            copyItem.data.withoutCellEndCounts = 0

            if(item.data.withoutCellDeclaredCounts !== 0 && item.data.withoutCellEndCounts === 0) {
              copyItem.data.withoutCellDeclaredCounts = item.data.withoutCellDeclaredCounts
              copyItem.data.withoutCellEndCounts = item.data.withoutCellEndCounts
              flag = true
            }

            let productCells = []
            if(copyItem.data.UserProductCellArray.length > 0){
              copyItem.data.UserProductCellArray.map(cell => {
                if(parseInt(cell.data.storageCount) !== 0 && parseInt(cell.data.cellCount) === 0) {
                  productCells.push(cell)
                  flag = true
                }
              })
            }
            copyItem.data.UserProductCellArray = productCells

            productCells = []
            if(copyItem.data.UserMixedProductCellArray.length > 0){
              copyItem.data.UserMixedProductCellArray.map(cell => {
                if(parseInt(cell.data.storageCount) !== 0 && parseInt(cell.data.cellCount) === 0) {
                  productCells.push(cell)
                  flag = true
                }
              })
            }
            copyItem.data.UserMixedProductCellArray = productCells

            if(flag) {
              newArray.push(copyItem)
            }
          }

          if(this.navTabs.scanned.active) {
            let flag = false
            let flagWithoutCell = false
            let copyItem = this._.cloneDeep(item)

            if(item.data.withoutCellDeclaredCounts === item.data.withoutCellEndCounts && item.data.withoutCellDeclaredCounts !== 0) {
              flag = true
              flagWithoutCell = true
            }

            let productCells = []
            if(copyItem.data.UserProductCellArray.length > 0){
              copyItem.data.UserProductCellArray.map(cell => {
                if(
                    (parseInt(cell.data.storageCount) !== 0 && parseInt(cell.data.cellCount) !== 0) &&
                    parseInt(cell.data.storageCount) === parseInt(cell.data.cellCount)) {
                  productCells.push(cell)
                  flag = true
                }
              })
            }
            copyItem.data.UserProductCellArray = productCells

            productCells = []
            if(copyItem.data.UserMixedProductCellArray.length > 0){
              copyItem.data.UserMixedProductCellArray.map(cell => {
                if(
                    (parseInt(cell.data.storageCount) !== 0 && parseInt(cell.data.cellCount) !== 0) &&
                    parseInt(cell.data.storageCount) === parseInt(cell.data.cellCount)) {
                  productCells.push(cell)
                  flag = true
                }
              })
            }
            copyItem.data.UserMixedProductCellArray = productCells

            if(!flagWithoutCell){
              let sameProduct = this._.filter(this.Inventory.data.productsForCellArray, (item) => {
                return item.data.Product.product.id === copyItem.data.Product.product.id
              })

              if(
                  !flag &&
                  sameProduct.length === 1 &&
                  !sameProduct[0].data.UserMixedProductCellArray.length &&
                  !sameProduct[0].data.UserProductCellArray.length &&
                  copyItem.data.withoutCellDeclaredCounts === copyItem.data.withoutCellEndCounts
              ) {
                flag = true
              } else {
                copyItem.data.withoutCellDeclaredCounts = null
                copyItem.data.withoutCellEndCounts = null
              }

            }

            if(flag) {
              newArray.push(copyItem)
            }

          }

          if(this.navTabs.all.active) {
            newArray.push(item)
          }
        })
        this.filteredArray = newArray
      },

      setFilteredTableUser() {
        let newArray = []

        this.Inventory.data.productsForCellArray.map(item => {

          let startProductCounts = 0
          let endProductCounts = 0

          startProductCounts += parseInt(item.data.withoutCellDeclaredCounts)
          endProductCounts += parseInt(item.data.withoutCellEndCounts)

          item.data.UserProductCellArray.map(cell => {
            startProductCounts += parseInt(cell.data.storageCount)
            endProductCounts += parseInt(cell.data.cellCount)
          })

          item.data.UserMixedProductCellArray.map(cell => {
            startProductCounts += parseInt(cell.data.storageCount)
            endProductCounts += parseInt(cell.data.cellCount)
          })

          if(this.navTabs.edited.active ) {

            if(startProductCounts !== endProductCounts && endProductCounts !== 0) {
              newArray.push(item)
            }

          }

          if(this.navTabs.unscanned.active) {

            if(startProductCounts !== 0 && endProductCounts === 0) {
              newArray.push(item)
            }

          }

          if(this.navTabs.scanned.active) {

            if(startProductCounts === endProductCounts) {
              newArray.push(item)
            }

          }

          if(this.navTabs.all.active) {
            newArray.push(item)
          }
        })

        this.filteredArray = newArray
      },
    },

  }
</script>

<style scoped>

</style>